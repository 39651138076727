import React from 'react';
import '../css/card.css'; // External CSS file for styling
import { useNavigate } from 'react-router-dom'


const Card = ({ title, content, titleColor ,contentColor,navigatePath,cardColor}) => {
  const navigate = useNavigate();
    const cardStyle = {
        background: cardColor,
        cursor: navigatePath ? 'pointer' : 'default',
        border: '1px solid #ccc',
        padding: '8px',
        margin: '8px',
        boxSizing: 'border-box',
        flex: '0 0 100%',
      //  flex: fullWidth ? '0 0 100%' : '1 1 auto', // Full width if fullWidth is true
        display: 'flex',
        flexDirection: 'column',
        justifyContent: content ? 'flex-start' : 'center', // Center vertically if content is not available
        //height: '150px', // Set a fixed height for better centering
        alignItems: 'center', 
      };



  return (

     //onClick={onClick} style={{ cursor: 'pointer', border: '1px solid #ccc', padding: '10px', margin: '10px' }}
   //style={cardStyle}
   //style={{background: cardColor,cursor: onClick ? 'pointer' : 'default',  padding: '10px', margin: '10px'}}
<div className="card"  onClick={() => navigate(navigatePath)} style={cardStyle} >
    <h4 className="card-title" style={{color: titleColor }} >{title}</h4>
      <p className="card-content" style={{color: contentColor}}>{content}</p>
    </div>
   
  );
};

export default Card;
