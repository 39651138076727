import React, { useState, useEffect, useLayoutEffect } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import TokenService from "../utilities/TokenService";
import CryptoJS from 'crypto-js';
const AtAGlancePaliFamily = () => {
  const [family, setFamily] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null); // Track selected member

  useEffect(() => {
    const fetchFamilyData = async () => {
      try {
        const tokenServiceDetails = TokenService.getUserDetails();
        const urights = tokenServiceDetails?.userrights;
        const userid = urights;
       const secretKey = 'palifamily';
       const encryptedUserId = CryptoJS.AES.encrypt(String(userid), secretKey).toString();
      const response = await axios.get(`/familytree?userid=${encodeURIComponent(encryptedUserId)}`);
       // Sort family members by date of birth
        const sortedFamily = response.data.sort((a, b) => {
          const dateA = dayjs(a.date_of_birth);
          const dateB = dayjs(b.date_of_birth);
          return dateA.isBefore(dateB) ? -1 : 1; // Sort in ascending order
        });
        setFamily(sortedFamily);
  
        // Set the root member as the selected member by default
        const rootMember = sortedFamily.find(
          (member) => member.father_id === null && member.mother_id === null && member.partner_id === null
        );
  
        if (rootMember) {
          setSelectedMember(rootMember.id); // Set the root member as selected
        }
      } catch (error) {
        console.error('Error fetching family data:', error);
      }
    };
    fetchFamilyData();
  }, []);

  useLayoutEffect(() => {
    document.body.style.background =
      "linear-gradient(to right, #000444, #0000ff)";
  }, []);


  return (
    <div>
      <div className="family-member">
        <h1 style={{ color: 'navy' }}>Pali , Gorakhpur - 273403 Family Chart</h1>
      </div>

      {selectedMember && (
        <FamilyMember
          member={family.find((member) => member.id === selectedMember)}
          family={family}
          selectedMember={selectedMember}
          setSelectedMember={setSelectedMember}
        />
      )}
    </div>
  );
};

const FamilyMember = ({ member, family, selectedMember, setSelectedMember }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const partner = family.find((fam) => fam.partner_id === member.id);

  const children = family.filter(
    (child) => child.father_id === member.id || child.mother_id === member.id
  );

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  const handleClick = () => {
    setSelectedMember(member.id);
  };

  return (
    <div className={`family-member ${selectedMember === member.id ? 'selected' : 'parent'}`} onClick={handleClick}>
      <h3 onClick={toggleExpand}>
        {member.name} <span style={{ color: "green" }}> {member.date_of_birth? "( DoB : " + formatDate(member.date_of_birth) + " )":''} </span>  <span style={{ color: "red" }}>{member.dateofDaith?"( DoD : " + formatDate(member.dateofDaith) + " )":''}</span>
      </h3>

      {partner ? (
        <h4>      {partner.name} <span style={{ color: "green" }}> {partner.date_of_birth? "( DoB : " + formatDate(partner.date_of_birth) + " )":''} </span>  <span style={{ color: "red" }}>{partner.dateofDaith?"( DoD : " + formatDate(partner.dateofDaith) + " )":''}</span>
</h4>
      ) : ""}

      {isExpanded && (
        <div>
          {children.map((child) => (
            <FamilyMember
              key={child.id}
              member={child}
              family={family}
              selectedMember={selectedMember}
              setSelectedMember={setSelectedMember}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const formatDate = (date) => (date ? dayjs(date).format('DD-MMM-YYYY') : null);

export default AtAGlancePaliFamily;
