import { Outlet, Navigate } from 'react-router-dom';
import { useEffect } from 'react';

function PublicRoutes() {
    useEffect(() => {
        sessionStorage.clear();
        <Navigate to='/' />
    },[])
    return <Outlet />
}

export default PublicRoutes