import { Routes, Route } from "react-router-dom";
import React from "react";

//Security Routes
import PrivateRoutes from "./utilities/privateRoutes.js";
import PublicRoutes from "./utilities/publicRoutes.js";

import Dashboard from "./homepage/Index.js";
import Family from "./PaliFamily/PaliFamily.js";
import NewFamilyMember from "./PaliFamily/AddFamilyMembers.js";
import AllFamily from "./PaliFamily/AtAGlancePaliFamily.js";
import UpdateFamilyMember from "./PaliFamily/updateFamilyMembers.js";
import SignIn from "./Login/login.js";
import "./css/form.css";
import "./css/card.css";
import "./css/familytree.css";
import AcessWithUserRight from "./utilities/AcessWithUserRight.js";
import ErrorPage from "./ErrorPage/ErrorPage.js";

// import dotenv from 'dotenv';
// import path from 'path';

// dotenv.config({path: path.join(__dirname, '..', '.env')});


function App() {
  return (
    <>
      <Routes>
        <Route element={<PrivateRoutes />}>

         <Route element={<Family />} path="/Family_Dashboard" />

         
          {/* Conditional rendering for specific userrights */}
          <Route element={<AcessWithUserRight />}>
            <Route element={<Dashboard />} path="/Admin_Dashboard" />
            <Route element={<AllFamily />} path="/allfamily" />
            <Route element={<NewFamilyMember />} path="/addfamily" />
            <Route element={<UpdateFamilyMember />} path="/updatefamily" />
          </Route>
        </Route>

        {/* Public Routes */}
        <Route element={<PublicRoutes />}>
          <Route element={<SignIn />} path="/" />
         
        </Route>

        {/* <Route element={<ErrorPage />} path="*" /> */}
        <Route component={ErrorPage} />
      </Routes>
    </>
  );
}

export default App;
