import React, { useState, useEffect, useLayoutEffect } from "react";
import axios from "axios";
import { TextField, Button, MenuItem, Grid } from "@mui/material";
import dayjs from "dayjs";
import CryptoJS from 'crypto-js';
import TokenService from "../utilities/TokenService";
function UpdateFamilyMember() {
  const [members, setMembers] = useState([]);
  const [selectedMemberId, setSelectedMemberId] = useState("");
  const [name, setName] = useState("");
  const [sex, setSex] = useState("male");
  const [fatherId, setFatherId] = useState("");
  const [motherId, setMotherId] = useState("");
  const [partnerId, setPartnerId] = useState("");
  const [dob, setDob] = useState("");
  const [dod, setDod] = useState("");
  const [birthplace, setBirthplace] = useState("");
  const [mobile1, setMobile1] = useState("");
  const [mobile2, setMobile2] = useState("");
  const [currentAddress, setCurrentAddress] = useState("");
  const [emailId, setEmailId] = useState("");
  //const today = dayjs();
  useEffect(() => {
    // Fetch all family members
    const tokenServiceDetails = TokenService.getUserDetails();
    const urights = tokenServiceDetails?.userrights;
    const userid = urights;
   const secretKey = 'palifamily';
   const encryptedUserId = CryptoJS.AES.encrypt(String(userid), secretKey).toString();
  //const response = await axios.get(`/familytree?userid=${encodeURIComponent(encryptedUserId)}`);
  
    axios
      .get(`/familytree?userid=${encodeURIComponent(encryptedUserId)}`)
      .then((response) => {
        setMembers(response.data);
      })
      .catch((error) => {
        console.error("Error fetching family members:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch the selected member's data when a member is selected
    if (selectedMemberId) {
      const member = members.find((m) => m.id === selectedMemberId);
      if (member) {
        setName(member.name);
        setSex(member.sex);
        setFatherId(member.father_id || "");
        setMotherId(member.mother_id || "");
        setPartnerId(member.partner_id || "");
  
        const birthdate = member.date_of_birth ? dayjs(member.date_of_birth).format("YYYY-MM-DD") : "";
        const deathdate = member.dateofDaith ? dayjs(member.dateofDaith).format("YYYY-MM-DD") : "";
        
        setDob(birthdate); // Set date or empty string if not available
        setDod(deathdate); // Set date or empty string if not available
        setBirthplace(member.birthplace);
        setMobile1(member.mobile1);
        setMobile2(member.mobile2);
        setCurrentAddress(member.currentaddress);
        setEmailId(member.emailid);
      }
    } else {
      // Reset fields if no member is selected
      resetForm();
    }
  }, [selectedMemberId, members]);




  const resetForm = () => {
    setName("");
    setSex("male");
    setFatherId("");
    setMotherId("");
    setPartnerId("");
    setDob("");
    setDod("");
    setBirthplace("");
    setMobile1("");
    setMobile2("");
    setCurrentAddress("");
    setEmailId("");
  };

  const handleUpdate = (e) => {

   

    e.preventDefault();

    // const formattedDate = dayjs(dob).format('YYYY-MM-DD');
    // console.log(formattedDate);

    const secretKey = 'palifamily';
    const encryptedid = CryptoJS.AES.encrypt(String(selectedMemberId), secretKey).toString();
     const updatedMember = {
      id: encryptedid, // Assuming the API expects an ID for updates
      name,
      sex,
      father_id: fatherId || null,
      mother_id: motherId || null,
      partner_id: partnerId || null,
      dateofbirth: dob,
      dateofDeath: dod,
      birthplace,
      mobile1,
      mobile2,
      current_address: currentAddress,
      email_id: emailId,
    };

    // console.log(selectedMemberId);
    // console.log(name);
    // console.log(sex);
    // console.log(fatherId);
    // console.log(motherId);
    // console.log(partnerId);
    // console.log(dob);
    // console.log(dod);
    // console.log(birthplace);
    // console.log(mobile1);
    // console.log(mobile2);
    // console.log(currentAddress);
    // console.log(emailId);

    //
    axios
      .post("updatefamilymember", updatedMember) // Assuming a PUT request for updates
      .then((response) => {
        alert("Family member updated successfully!");
        resetForm();
        setSelectedMemberId(""); // Reset selection
      })
      .catch((error) => {
        console.error("Error updating family member:", error);
      });
  };

  const getSpouseName = (partnerId) => {
    const spouse = members.find((member) => member.id === partnerId);
    return spouse ? spouse.name : "";
  };

  useLayoutEffect(() => {
    document.body.style.background =
      "linear-gradient(to right, #000444, #0000ff)";
  }, []);

  return (
    <div className="family-member" style={{ padding: 20 }}>
      <h2 style={{ color: "red" }}>Update Family Member</h2>
      <form onSubmit={handleUpdate}>

        <Grid container spacing={2}>
        <Grid item xs={12}>
        <TextField
              select
              label="Select Family Member"
              value={selectedMemberId}
              onChange={(e) => setSelectedMemberId(e.target.value)}
              fullWidth
              required
            >
              <MenuItem value="">Select Member</MenuItem>
              {members
                .sort((a, b) => a.name.localeCompare(b.name)) // Sort members by name
                .map((member) => (
                  <MenuItem key={member.id} value={member.id}>
                    {member.name}
                    {member.partner_id
                      ? ` (Spouse: ${getSpouseName(member.partner_id)})`
                      : ""}
                      ({member.id})
                  </MenuItem>
                ))}
            </TextField>
            </Grid>

          <Grid item xs={12}>
          <TextField
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
              required
            />
          </Grid>

          <Grid item xs={12} sm={6}>
          <TextField
              label="Mobile 1"
              value={mobile1}
              onChange={(e) => setMobile1(e.target.value)}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Mobile 2"
              value={mobile2}
              onChange={(e) => setMobile2(e.target.value)}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Email ID"
              value={emailId}
              onChange={(e) => setEmailId(e.target.value)}
              type="email"
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Current Address"
              value={currentAddress}
              onChange={(e) => setCurrentAddress(e.target.value)}
              fullWidth
              multiline
              rows={3}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Date of Birth "
              value={dob}
              onChange={(e) => setDob(e.target.value)}
              type="date"
              // InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Date of Death "
              value={dod}
              onChange={(e) => setDod(e.target.value)}
              type="date"
              // InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Birthplace"
              value={birthplace}
              onChange={(e) => setBirthplace(e.target.value)}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              select
              label="Sex"
              value={sex}
              onChange={(e) => setSex(e.target.value)}
              fullWidth
              required
            >
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              select
              label="Father"
              value={fatherId}
              onChange={(e) => setFatherId(e.target.value)}
              fullWidth
            >
              <MenuItem value="">Select Father</MenuItem>
              {members
                .sort((a, b) => a.name.localeCompare(b.name)) // Sort members by name
                .filter((member) => member.sex === "male")
                .map((member) => (
                  <MenuItem key={member.id} value={member.id}>
                    {member.name}
                    {member.partner_id
                      ? ` (Spouse: ${getSpouseName(member.partner_id)})`
                      : ""}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              select
              label="Mother"
              value={motherId}
              onChange={(e) => setMotherId(e.target.value)}
              fullWidth
            >
              <MenuItem value="">Select Mother</MenuItem>
              {members
                .sort((a, b) => a.name.localeCompare(b.name)) // Sort members by name
                .filter((member) => member.sex === "female")
                .map((member) => (
                  <MenuItem key={member.id} value={member.id}>
                    {member.name}
                    {member.partner_id
                      ? ` (Spouse: ${getSpouseName(member.partner_id)})`
                      : ""}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              select
              label="Partner"
              value={partnerId}
              onChange={(e) => setPartnerId(e.target.value)}
              fullWidth
            >
              <MenuItem value="">Select Partner</MenuItem>
              {members
                .sort((a, b) => a.name.localeCompare(b.name)) // Sort members by name
                .map((member) => (
                  <MenuItem key={member.id} value={member.id}>
                    {member.name}
                    {member.partner_id
                      ? ` (Spouse: ${getSpouseName(member.partner_id)})`
                      : ""}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <Button variant="contained" color="primary" type="submit" fullWidth>
              Update Family Member
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default UpdateFamilyMember;
