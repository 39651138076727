import React from "react";
import TokenService from "./TokenService";
import { Outlet } from "react-router-dom";
import ErrorPage from "../ErrorPage/ErrorPage";

export default function AcessWithUserRight() {
  const tokenServiceDetails = TokenService.getUserDetails();
  const urights = tokenServiceDetails?.userrights;

  if (urights !==8) {
    return <ErrorPage />;
  }

  return <Outlet />;
}
