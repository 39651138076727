import React, { useLayoutEffect } from "react";
import Card from "../utilities/Card";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
function Index() {
  useLayoutEffect(() => {
    document.body.style.background =
      "linear-gradient(to right, #000444, #0000ff)";
  }, []);

  return (
    <div style={{padding:20}}>
     
      

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ArrowDownwardIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          style={{ textAlign: "center" }}
        >
          <Typography variant="h5" // or any variant you prefer
          sx={{ textAlign: 'center', flexGrow: 1,color: "purple" }} >
          🏡 पाली परिवार 🏡
          </Typography>
        </AccordionSummary>
        <AccordionDetails>

       <div className="card-container">
        <Card
          title="Family Wise Tree"
          cardColor={"navy"}
          titleColor="white"
          content="Mobile View"
          contentColor={"white"}
          navigatePath="/Family_Dashboard"
        />

        <Card
          title="Family Hierarchy Tree"
          titleColor="white"
          content="Best View on Larger Screen"
          contentColor="white"
          navigatePath="/allfamily"
          cardColor="green"
        />

        <Card
          title="Add New Member"
          cardColor="red"
          titleColor="white"
          content="Add New Family Member"
          contentColor="white"
          navigatePath="/addfamily"
        />
        <Card
          title="Update Old Member"
          cardColor="black"
          titleColor="white"
          content="Update Family Member"
          contentColor={"white"}
          navigatePath="/updatefamily"
        />
         </div>
        </AccordionDetails>
        </Accordion>


        <Accordion>
        <AccordionSummary
          expandIcon={<ArrowDownwardIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          style={{ textAlign: "center" }}
        >
          <Typography variant="h5" // or any variant you prefer
          sx={{ textAlign: 'center', flexGrow: 1,color: "purple" }} >
          ⚙️Utilities⚙️
          </Typography>
        </AccordionSummary>
        <AccordionDetails>

       <div className="card-container">
        <Card
          title="Utilities"
          cardColor={"black"}
          titleColor="white"
          content="Tools here"
          contentColor={"white"}
          // navigatePath="/Family_Dashboard"
        />

        
         </div>
        </AccordionDetails>
        </Accordion>
     
    </div>
  );
}

export default Index;
