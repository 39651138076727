import React, { useState, useEffect, useLayoutEffect } from "react";
import axios from "axios";
import dayjs from "dayjs";


import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import TokenService from "../utilities/TokenService";
import CryptoJS from 'crypto-js';
import Card from "../utilities/Card";

let userid='';
const FamilyTree = () => {
  const [family, setFamily] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null); // Track selected member
  const [generation, setGeneration] = useState(1); // Track generation
  const [birthdaysToday, setBirthdaysToday] = useState([]); // Members with birthdays today
  const [birthdaysThisMonth, setBirthdaysThisMonth] = useState([]); // Members with birthdays today
  const [deathThisMonth, setDeathThisMonth] = useState([]); // Members with birthdays today
  const [totalMember,setTotalMember]=useState([]);
  const [totaldeathMember,setTotalDeathMember]=useState([]);
  const [totalMaleMember,settotalMaleMember]=useState([]);
  const [totalDeathMaleMember,settotalDeathMaleMember]=useState([]);

  const [familyMap, setFamilyMap] = useState(new Map());

 
  useEffect(() => {


// Inside the fetchFamilyData function, after sorting the family







    const fetchFamilyData = async () => {
      try {
        const tokenServiceDetails = TokenService.getUserDetails();
        const urights = tokenServiceDetails?.userrights;
        userid = urights;
       const secretKey = 'palifamily';
       const encryptedUserId = CryptoJS.AES.encrypt(String(userid), secretKey).toString();
      const response = await axios.get(`/familytree?userid=${encodeURIComponent(encryptedUserId)}`);


      
      const sortedFamily = response.data.sort((a, b) => {
          const dateA = dayjs(a.date_of_birth);
          const dateB = dayjs(b.date_of_birth);
          return dateA.isBefore(dateB) ? -1 : 1;
        });
        setFamily(sortedFamily);
  
        // Set the root member as the selected member by default
        setTotalMember(sortedFamily.length);

        const rootMember = sortedFamily.find(
          (member) =>
            member.father_id === null &&
            member.mother_id === null &&
            member.partner_id === null
        );
  
        if (rootMember) {
          setSelectedMember(rootMember.id);
          setGeneration(1);
        }
  
        // Find members whose birthday is today (compare only day and month)
        const today = dayjs(); // Get today's date
  
        const todayBirthdays = sortedFamily.filter((member) => {
          if (!member.date_of_birth) return false; // Skip if no birthdate is available
            const memberBirthday = dayjs(member.date_of_birth); // Parse member's birthdate
            // Compare the day and month only
          return (
            memberBirthday.date() === today.date() && 
            memberBirthday.month() === today.month()
          );
        });
  
        setBirthdaysToday(todayBirthdays); // Set members with birthdays today
  

        const monthBirthdays = sortedFamily
        .filter((member) => {
          if (!member.date_of_birth) return false;
          const memberBirthday = dayjs(member.date_of_birth);
          return memberBirthday.month() === today.month();
        })
        .sort((a, b) => {
          const dayA = dayjs(a.date_of_birth).date();
          const dayB = dayjs(b.date_of_birth).date();
          return dayA - dayB; // Sort by day in ascending order
        });

  
        setBirthdaysThisMonth(monthBirthdays); // Set members with birthdays today
        

        const monthDeathdays = sortedFamily
        .filter((member) => {
          if (!member.dateofDaith) return false;
          const memberDeathday = dayjs(member.dateofDaith);
          return memberDeathday.month() === today.month();
        })
        .sort((a, b) => {
          const dayA = dayjs(a.dateofDaith).date();
          const dayB = dayjs(b.dateofDaith).date();
          return dayA - dayB; // Sort by day in ascending order
        });

  
        setDeathThisMonth(monthDeathdays); // Set members with birthdays today
  
 


const totalDeathMemberscount = sortedFamily.filter((member) => member.dateofDaith).length;

const totalMale=sortedFamily.filter((member) => member.sex === 'male').length;

const totalDeathMaleMemberscount = sortedFamily.filter((member) => (member.dateofDaith && member.sex === 'male')).length;

const familyMap = new Map();
sortedFamily.forEach((member) => {
  familyMap.set(member.id, member);
});
setFamilyMap(familyMap); // You need to add a state for familyMap


setTotalDeathMember(totalDeathMemberscount);
settotalMaleMember(totalMale);
settotalDeathMaleMember(totalDeathMaleMemberscount);

       
      } catch (error) {
        console.error("Error fetching family data:", error);
      }
    };
    fetchFamilyData();
  }, []);
  
  

  ///////////////////////////BACK to FATHER EVENT///////////////////////////////////////////////////
  const handleBackToFather = () => {
    const currentMember = family.find((member) => member.id === selectedMember);

    if (currentMember) {
      if (currentMember.father_id) {
        setSelectedMember(currentMember.father_id);
        setGeneration((prev) => Math.max(prev - 1, 1));
      } else {
        const partner = family.find(
          (member) => member.id === currentMember.partner_id
        );
        if (partner && partner.father_id) {
          setSelectedMember(partner.father_id);
          setGeneration((prev) => Math.max(prev - 1, 1));
        }
      }
    }
  };

  const currentMember = family.find((member) => member.id === selectedMember);
  const partner = currentMember
    ? family.find((member) => member.id === currentMember.partner_id)
    : null;

  useLayoutEffect(() => {
    document.body.style.background =
      "linear-gradient(to right, #000444, #0000ff)";
  }, []);


  const [searchTerm, setSearchTerm] = useState("");

  // Function to handle search input change
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredFamily = family
  .slice()
  .filter((member) =>
    member.name.toLowerCase().includes(searchTerm.toLowerCase())
  )
  .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div>


      <div className="family-member" style={{background:"navy"}}>
      <h2 style={{ color: "white" }}>
      🙏पाली परिवार के सदस्य के रुप मे आपका हार्दिक स्वागत है🙏</h2>
       </div>


      {/* Display members with birthdays today */}
      {birthdaysToday.length > 0 && (
        <div className="family-member" style={{background:"pink"}}>
          <h2 style={{ color: "purple" }}>🎉🎉परिवार के निम्न सदस्यो को आज जन्मदिन की हार्दिक शुभकामनाएं 🎉🎉</h2>
          {birthdaysToday
          .filter((member) => !member.dateofDaith)
          .map((member) => (
            <div
              key={member.id}
              style={{
                border: "1px solid #ddd",
                padding: "10px",
                marginBottom: "10px",
                backgroundColor: "#f9f9f9",
              }}
            >
              <h2 style={{ color: "green" }}>🎂🎂 {member.name}🎂🎂 </h2> 
              {member.sex === 'male' ? '👨' : '👩'}
              जन्मतिथि: <strong>{formatDate(member.date_of_birth)}</strong>
             &nbsp;&nbsp; {member.mobile1 && <>सम्पर्कसुत्र:  <strong>{member.mobile1}</strong></>}
             &nbsp;&nbsp; {member.mobile2 && <>&   &nbsp;  <strong>{member.mobile2}</strong></>}
            </div>
          ))}
        </div>
      )}



<div className="family-member">
        <h2 style={{ color: "navy" }}>
          {/* Pali, Gorakhpur - 273403 Family Chart */}

          <p>🏡 ग्राम व पोस्ट - पाली, थाना-सहजनवा, जिला-गोरखपुर, उ0प्र0 - भारत </p>
          <p> पिन संख्या - 273403</p>
          </h2>
          <h1> 👨‍👩‍👧‍👧वंशावली👨‍👩‍👦‍👦 </h1>
          <h2>  गोत्र - कश्यप </h2>
       


      {selectedMember && (
        <FamilyMember
          member={family.find((member) => member.id === selectedMember)}
          family={family}
          selectedMember={selectedMember}
          setSelectedMember={setSelectedMember}
          generation={generation} // Pass generation as prop
          setGeneration={setGeneration} // Pass generation setter as prop
        />
      )}

      <div className="family-member">
        {/* Back to Father button */}
        <button
          onClick={handleBackToFather}
          disabled={!(currentMember?.father_id || partner?.father_id)} // Disable if neither father nor partner's father is available
        >
          Back to Parents
        </button>
      </div>
      </div>     
 

{/* Birthday for the Month */}

<div className="family-member">
<Accordion>
        <AccordionSummary
          expandIcon={<ArrowDownwardIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          style={{ textAlign: "center" }}
        >
          <Typography variant="h5" // or any variant you prefer
          sx={{ textAlign: 'left', flexGrow: 1 }} >


 <div className="card-container">
 <Card
          title={`कुल सदस्य ${totalMember}`}
          titleColor="white"
          content={`👨${totalMaleMember} 👩${totalMember - totalMaleMember}`}
          contentColor="white"
          cardColor="navy"
        />

<Card
          title={` जीवित ${totalMember-totaldeathMember}`}
          titleColor="white"
          content={`👨 ${totalMaleMember-totalDeathMaleMember} 👩${(totalMember-totalMaleMember)-(totaldeathMember-totalDeathMaleMember)}`}
          contentColor="white"
          cardColor="green"
        />

<Card
          title={`😢गोलोकवासी ${totaldeathMember}`}
          titleColor="white"
          content={`👨 ${totalDeathMaleMember}   👩  ${totaldeathMember-totalDeathMaleMember}`}
          contentColor="white"
          cardColor="red"
        />
         
         </div>

          
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
 
        {family.length > 0 && (
   <div>
   {/* Search Input */}
   <div style={{ margin: "20px 0" }}>
     <input
       type="text"
       placeholder="Find by name"
       value={searchTerm}
       onChange={handleSearchChange}
       style={{ padding: "10px", width: "200px" }}
     />
     <button
       onClick={() => setSearchTerm("")}
       style={{ marginLeft: "10px", padding: "10px" }}
     >
       Clear
     </button>
   </div>

   {/* Table for displaying family members */}
   <div style={{ overflowX: "auto" }}>
  <table style={{ width: "100%", borderCollapse: "collapse", margin: "0 auto" }}>
    <thead>
      <tr>
        <th>#</th>
        <th>सदस्य</th>
        <th>👨‍👧पिता / ❤️जीवनसाथी</th>
        <th>👩माता</th>
      </tr>
    </thead>
    <tbody>
      {filteredFamily.map((member, index) => {
        const father = member.father_id ? familyMap.get(member.father_id) : null;
        const mother = member.mother_id ? familyMap.get(member.mother_id) : null;
        const partner = member.partner_id ? familyMap.get(member.partner_id) : null;

        return (
          <tr key={member.id}>
            <td style={{ border: "1px solid black", padding: "10px", textAlign: "left" }}>
              {index + 1}  {member.dateofDaith ? "😢" : ""}
            </td>
            <td style={{ border: "1px solid black", padding: "10px", textAlign: "left" }}>
              <h3 style={{ color: member.dateofDaith ? "red" : "green", margin: 0 }}>
                {member.sex === "male" ? "👨" : "👩"}&nbsp;&nbsp;
                {member.name}
              </h3>
            </td>
            <td style={{ border: "1px solid black", padding: "10px", textAlign: "left" }}>
              {/* Display father if available, otherwise display partner with the relevant symbol */}
              {father ? `👨‍👧 ${father.name}` : partner ? `❤️ ${partner.name}` : ""}
            </td>
            <td style={{ border: "1px solid black", padding: "10px", textAlign: "left" }}>
              {mother ? mother.name : ""}
            </td>
          </tr>
        );
      })}
    </tbody>
  </table>
</div>

<style jsx>{`
  @media only screen and (max-width: 768px) {
    table {
      font-size: 14px;
    }
    td, th {
      padding: 8px;
    }
  }

  @media only screen and (max-width: 480px) {
    td, th {
      padding: 5px;
    }
    h3 {
      font-size: 16px;
    }
  }
`}</style>


 </div>

)}

 </AccordionDetails>
 </Accordion>
</div>





<div className="family-member">
<Accordion>
        <AccordionSummary
          expandIcon={<ArrowDownwardIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          style={{ textAlign: "center" }}
        >
          <Typography variant="h5" // or any variant you prefer
          sx={{ textAlign: 'center', flexGrow: 1,color: "purple" }} >
          🎉🎂 वर्तमान माह मे  जन्म लेने वाले सदस्यो की सुची🎂🎉
          </Typography>
        </AccordionSummary>
        <AccordionDetails>

{birthdaysThisMonth.length > 0 && (
        <div className="family-member" style={{background:"Linen"}}>
          {/* <h2 style={{ color: "purple" }}>🎉🎂 Birthdays This Month 🎂🎉</h2> */}
          {birthdaysThisMonth
          //  .filter((member) => !member.dateofDaith)
          .map((member) => (
            <div
              key={member.id}
              style={{
                border: "1px solid #ddd",
                padding: "10px",
                marginBottom: "10px",
                backgroundColor: "#f9f9f9",
              }}
            >
              <h3 style={{ color: member.dateofDaith ?"red" : "green" }}>
              {member.dateofDaith?   '😢' :""}
                {member.sex === 'male' ? '👨' : '👩'} 
              &nbsp;&nbsp; {member.name} 
                
              
              &nbsp;&nbsp;🎂जन्मतिथि: <strong>{formatDate(member.date_of_birth)}</strong>
                </h3> 
              
              
            
                    &nbsp;&nbsp; {member.mobile1 && <>📱सम्पर्कसुत्र:  <strong>{member.mobile1}</strong></>}
             &nbsp;&nbsp; {member.mobile2 && <>&   &nbsp;  <strong>{member.mobile2}</strong></>}

             
            </div>
          ))}
        </div>
      )}
 </AccordionDetails>
 </Accordion>
</div>







 {/* Display members with leave us thismonth */}
 <div className="family-member">
<Accordion>
        <AccordionSummary
          expandIcon={<ArrowDownwardIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          style={{ textAlign: "center" }}
        >
          <Typography variant="h5" // or any variant you prefer
          sx={{ textAlign: 'center', flexGrow: 1,color: "red" }} >
          😔😔वर्तमान माह मे गोलोकवासी हो गये सदस्यो की सुची😔😔
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
 
 {deathThisMonth.length > 0 && (
        <div >
         {deathThisMonth.map((member) => (
            <div
              key={member.id}
              style={{
                border: "1px solid #ddd",
                padding: "10px",
                marginBottom: "10px",
                backgroundColor: "#f9f9f9",
              }}
            >
              <h3 style={{ color: "green" }}>{member.name}</h3> 
              🎂जन्मतिथि: <strong>{formatDate(member.date_of_birth)}</strong> &nbsp;&nbsp;
             😢मृत्युतिथि: <strong style={{ color: "red" }}> {formatDate(member.dateofDaith)}</strong>
             
            </div>
          ))}
        </div>
      )}
 </AccordionDetails>
 </Accordion>
</div>

    </div>
    
  );
};

//////////////////////////FAMILY MEMBER///////////////////////////////////////////////////

const FamilyMember = ({
  member,
  family,
  selectedMember,
  setSelectedMember,
  generation,
  setGeneration,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const partner = family.find((fam) => fam.partner_id === member.id);

  const children = family.filter(
    (child) => child.father_id === member.id || child.mother_id === member.id
  );

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  const handleClick = (e) => {
    e.stopPropagation();
    setSelectedMember(member.id);
  };

  const handleChildClick = (childId) => {

    const childe = family.find((fam) => fam.father_id === childId || fam.mother_id === childId);
    if (childe)
    {
      setSelectedMember(childId);
      setGeneration(generation + 1);
    

    }  
   
  };

  const getMemberColor = () => {
    if (selectedMember === member.id) {
      return "red";
    } else {
      return "green";
    }
  };

  return (
    <div className={`family-member`} onClick={handleClick}>
      <h3
        onClick={toggleExpand}
        style={{
          color: getMemberColor(),
          cursor: "pointer",
        }}
      >
       {member.sex === 'male' ? '👨' : '👩'} {member.name} (वंश क्रमांक: {generation})
      </h3>

      <p style={{ color: "green" }}>
        {member.date_of_birth ? (
          <>
           🎂जन्मतिथि: <strong>{formatDate(member.date_of_birth)}</strong>
          </>
        ) : (
          ""
        )}
        {member.dateofDaith ? (
          <>
           <strong style={{ color: "red" }} >😢मृत्युतिथि:  {formatDate(member.dateofDaith)}</strong>
          </>
        ) : null}
      </p>

      <div>
        {member.birthplace ? <p>🏡जन्मस्थान: <strong>{member.birthplace}</strong></p> : null}
        {member.currentaddress ? <p>🏠वर्तमान पता:  <strong>{member.currentaddress}</strong></p> : null}
        {member.mobile1 ? <p>📱सम्पर्कसुत्र: <strong> {member.mobile1}  </strong></p> : null}
      </div>

      {partner ? (
        <div style={{ backgroundColor: "navy", padding: "10px" }}>
          <span style={{ color: "white" }}>
          {partner.sex === 'male' ? '👨' : '👩'} जीवनसाथी {partner.name}
          &nbsp;&nbsp; 
          {partner.date_of_birth ? (
          <>
            &nbsp;&nbsp; 🎂जन्मतिथि: <strong>{formatDate(partner.date_of_birth)}</strong>
          </>
        ) : (
          ""
        )}

&nbsp;&nbsp; {partner.birthplace ? <>🏡जन्मस्थान: <strong>{partner.birthplace}</strong></> : null}

        {partner.dateofDaith ? (
          <>
            &nbsp;&nbsp; <strong style={{ color: "red" }} >😢मृत्युतिथि:  {formatDate(partner.dateofDaith)}</strong>
          </>
        ) : null}



          </span>
        </div>
      ) : null}

      {isExpanded && (
        <div>
          {children.map((child) => (
            <FamilyMember
              key={child.id}
              member={child}
              family={family}
              selectedMember={selectedMember}
              setSelectedMember={handleChildClick}
              generation={generation + 1}
              setGeneration={setGeneration}
            />
          ))}
        </div>
      )}
    </div>
  );
};

// Helper to format the date
//const formatDate = (date) => (date ? dayjs(date).format("DD-MMM-YYYY") : null);

const formatDate = (date) => {
  if (!date) return null;

  // Check if user === 1 to apply the masked format
  if (userid === 8) {
    return dayjs(date).format("DD-MMM-YYYY");
    
  } else {
    // Default format with year
    const formattedDate = dayjs(date).format("DD-MMM");
    return `${formattedDate}-****`;
  }
};


export default FamilyTree;
