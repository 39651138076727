import React, { useState, useLayoutEffect, useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import axios from "axios";
//import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TokenService from "../utilities/TokenService";
import ToasterAlerts from "../utilities/ToasterAlerts";
import EncryptService from "../utilities/EncryptionService";
import CryptoJS from 'crypto-js';

let email = "";
const responseip = await fetch("https://api.ipify.org?format=json");
const data = await responseip.json();
const ip = data.ip;
const ipp = ip + "";

const theme = createTheme({
  palette: {
    primary: {
      main: "#FF4500",
    },
    secondary: {
      main: "#dc004e",
    },
    background: {
      default: "#f5f5f5",
    },
  },
  typography: {
    h5: {
      fontWeight: 600,
    },
  },
});

function Copyright(props) {
  return (
    <Typography variant="body2" color="textSecondary" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="https://sriyansh.co.in">
        sriyansh.co.in
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function SignIn() {
  const [uname, setUname] = useState("");
  const [pastext, setPastext] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState();
  const [loginData, setLoginData] = useState([]);

  useEffect(() => {
    sessionStorage.clear();
  }, []);

  const sendOtp = async () => {
    try {
      const response = await axios.post("/send-otp", { email,uname });
      setError(response.data.message);
      setOtpSent(true);
    } catch (error) {
      setError("Failed to send OTP");
    }
  };

  const verifyOtp = async () => {
    try {
      await axios.post("/verifyotp", { email, otp });
      TokenService.setTokenDetails(loginData);
      sessionStorage.setItem("token1", EncryptService.encrypt(ipp));
      sessionStorage.setItem("token3", EncryptService.encrypt("erterte"));
      sessionStorage.setItem("token4", EncryptService.encrypt("dfgdfgdfg"));
      sessionStorage.setItem("token5", EncryptService.encrypt("sdfsdfs"));
      sessionStorage.setItem("token6", EncryptService.encrypt("sdfsdfs"));
      sessionStorage.setItem("token7", EncryptService.encrypt("sdfsdfsdfsdf"));
      sessionStorage.setItem(
        EncryptService.encrypt("token8"),
        EncryptService.encrypt(ipp)
      );

          
      loginData.userrights === 8 ? navigate("/Admin_Dashboard") : navigate("/Family_Dashboard");

      setOtp("");
      setUname("");
      setPastext("");
    } catch (error) {
      setError("Invalid OTP");
    }
  };

  const getdata = async () => {
    try {

      const secretKey = 'palifamily';
      const encryptedpasstext = CryptoJS.AES.encrypt(String(pastext), secretKey).toString();
    // const response = await axios.get(`/familytree?userid=${encodeURIComponent(encryptedUserId)}`);
     


      const response = await axios.post("/getLoginData", {
        uname,
        pastext : encryptedpasstext,
        ipp,
      });

      setLoginData(response.data[0]);
      email = response.data[0].emailid;

      sendOtp();
    } catch (error) {
      setError("Invalid login details. Please try again.");
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (uname.trim() === "" || pastext.trim() === "") {
      setError("Please fill in both fields.");
      return;
    }
    getdata();
  };

  useLayoutEffect(() => {
    document.body.style.background =
      "linear-gradient(to right, #000444, #0000ff)";
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CssBaseline />
        <Paper
          elevation={3}
          sx={{
            padding: 3,
            borderRadius: 2,
            backgroundColor: "#ffffff",
            width: "100%",
            maxWidth: "400px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <AccountBalanceIcon
              sx={{ color: theme.palette.primary.main, fontSize: 80 }}
            />
            <Typography
              component="h1"
              variant="h5"
              sx={{ mt: 2, mb: 2, color: theme.palette.primary.main }}
            >
              🙏सुस्वागतम🙏
            </Typography>


            {error && loginData.userrights!==8 && (
              <ToasterAlerts
                type="error"
                title="Login"
                errorMessage={error}
                displaytime={5000}
                handleClose={() => {
                  setError("");
                }}
              />
            )}

            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ width: "100%" }}
            >
              {!otpSent && (
                <>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="usname"
                    label="User Name"
                    name="uname"
                    value={uname}
                    onChange={(e) => setUname(e.target.value)}
                    autoFocus
                    variant="outlined"
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="pastext"
                    label="Password"
                    type="password"
                    id="password"
                    value={pastext}
                    onChange={(e) => setPastext(e.target.value)}
                    variant="outlined"
                  />

                  <button
                    className="button button1"
                    type="submit"
                    title="Send OTP"
                    style={{
                      width: "100%",
                      padding: "10px",
                      marginTop: "20px",
                      backgroundColor: theme.palette.primary.main,
                      color: "#fff",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                    }}
                  >
                    Generate OTP
                  </button>
                </>
              )}

              {otpSent && (
                <>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="otp"
                    label="OTP"
                    type="password"
                    id="otp"
                    value={otp}
                    autoFocus={true}
                    onKeyDown={(e) => {
                      const key = e?.key;
                      if (key === "Enter") {
                        verifyOtp();
                      }
                    }}
                    onChange={(e) => setOtp(e.target.value)}
                    variant="outlined"
                  />

                  <button
                    className="button button1"
                    type="button"
                    onClick={verifyOtp}
                    title="Click for Data Save"
                    style={{
                      width: "100%",
                      padding: "10px",
                      marginTop: "20px",
                      backgroundColor: theme.palette.primary.main,
                      color: "#fff",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                    }}
                  >
                    Login
                  </button>
                </>
              )}

              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Link href="#" variant="body2">
                  Forgot password
                </Link>
              </Box>
            </Box>
          </Box>
          <Copyright sx={{ mt: 4 }} />
        </Paper>
      </Container>
    </ThemeProvider>
  );
}
