import React from "react";
import { motion } from "framer-motion";
import "../css/Errorpage.css"; // Importing CSS for additional styles

const ErrorPage = () => {
  // sessionStorage.clear();
  // localStorage.clear();
  return (
    <div className="error-page">
      <motion.div
        initial={{ scale: 0 }}
        animate={{ rotate: 360, scale: 1 }}
        transition={{ duration: 1 }}
        className="error-icon"
      >
        😕
      </motion.div>
      <h1 className="error-title">404</h1>
      <p className="error-text">Oops!😠 We can't find the page you're looking for.</p>
      <motion.div
        initial={{ x: '-100vw' }}
        animate={{ x: 0 }}
        transition={{ type: "spring", stiffness: 50 }}
        className="error-message"
      >
        It's probably lost in space 🌌.
      </motion.div>
      <motion.a
        href="/"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        className="error-home-link"
      >
        Go back to safety 🏠
      </motion.a>
    </div>
  );
};

export default ErrorPage;
