import CryptoJS from "crypto-js";
const SECRET_KEY = "#3@!90&5%67DFghTQ!@45"; // In practice, keep this key secure and out of source control
class EncryptService {
  /**
   * Encrypts a given text.
   * @param {string} text - Thse text to encrypt.
   * @returns {string} - The encrypted text in Base64 format.
   */
  static encrypt = (text) => {
    try {
      const encrypted = CryptoJS.AES.encrypt(text, SECRET_KEY).toString();
      return encrypted;
    } catch (error) {
      console.error("Encryption error:", error);
      throw error;
    }
  };

  /**
   * Decrypts a given encrypted text.
   * @param {string} encryptedText - The encrypted text to decrypt.
   * @returns {string} - The decrypted text.
   */
  static decrypt = (encryptedText) => {
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedText, SECRET_KEY);
      const decrypted = bytes.toString(CryptoJS.enc.Utf8);
      return decrypted;
    } catch (error) {
      console.error("Decryption error:", error);
      throw error;
    }
  };
}

export default EncryptService;
