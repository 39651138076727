import { Outlet, Navigate } from 'react-router-dom';
import TokenService from './TokenService'; // Assuming you have this service

function PrivateRoutes() {
  // Fetch user details and userrights from TokenService
  const tokenServiceDetails = TokenService.getUserDetails();
  const urights = tokenServiceDetails?.userrights;

 // console.log("PrivateRoutes : " + urights);  // Log userrights for debugging

  // Example redirect based on userrights
  if (!urights) {
    return <Navigate to="/" />; // Redirect to the sign-in page if userrights are not available
  }

  return (
    <div>
      {/* Outlet renders nested routes */}
      <Outlet />
    </div>
  );
}

export default PrivateRoutes;
