import EncryptService from "./EncryptionService";
class TokenService {
    static getUserDetails = () => {
        const encryptedToken = sessionStorage.getItem('token');
        if(!encryptedToken) {
            return null;
        }
        const userDetails = JSON.parse(EncryptService.decrypt(encryptedToken));
        return userDetails;
    }
    static setTokenDetails = (body) => {
        const stringfyBody = JSON.stringify(body);
        const encrypedToken = EncryptService.encrypt(stringfyBody);
        sessionStorage.setItem('token',encrypedToken);
        
    }

    static getUserRight = () => {
        const tokenServiceDetails = TokenService.getUserDetails();
        const urights = tokenServiceDetails?.userrights;
        return urights;
    }

    static clearUserDetails() {
        localStorage.removeItem('token');
      }
}

export default TokenService;