import React, { useState, useEffect, useLayoutEffect } from "react";
import axios from "axios";
import { TextField, Button, MenuItem, Grid } from "@mui/material";
import CryptoJS from 'crypto-js';
import TokenService from "../utilities/TokenService";
function AddFamilyMembers() {
  const [name, setName] = useState("");
  const [sex, setSex] = useState("male");
  const [fatherId, setFatherId] = useState("");
  const [motherId, setMotherId] = useState("");
  const [partnerId, setPartnerId] = useState("");
  const [dob, setDob] = useState("");
  const [birthplace, setBirthplace] = useState("");
  const [mobile1, setMobile1] = useState("");
  const [mobile2, setMobile2] = useState("");
  const [currentAddress, setCurrentAddress] = useState("");
  const [emailId, setEmailId] = useState("");
  const [members, setMembers] = useState([]);

  useEffect(() => {
    const tokenServiceDetails = TokenService.getUserDetails();
    const urights = tokenServiceDetails?.userrights;
    const userid = urights;
   const secretKey = 'palifamily';
   const encryptedUserId = CryptoJS.AES.encrypt(String(userid), secretKey).toString();
  //const response = await axios.get(`/familytree?userid=${encodeURIComponent(encryptedUserId)}`);
  
    axios
      .get(`/familytree?userid=${encodeURIComponent(encryptedUserId)}`)
      .then((response) => {
        setMembers(response.data);
      })
      .catch((error) => {
        console.error("Error fetching family members:", error);
      });
  }, [sex]);



  const getSpouseName = (partnerId) => {
    const spouse = members.find((member) => member.id === partnerId);
    return spouse ? spouse.name : "";
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    const newFamilyMember = {
      name,
      sex,
      father_id: fatherId || null,
      mother_id: motherId || null,
      partner_id: partnerId || null,
      date_of_birth: dob,
      birthplace: birthplace,
      mobile1,
      mobile2,
      current_address: currentAddress,
      email_id: emailId,
    };

    axios
      .post("addfamilymember", newFamilyMember)
      .then((response) => {
        alert("Family member added successfully!");
        setName("");
        setSex("male");
        setFatherId("");
        setMotherId("");
        setPartnerId("");
        setDob("");
        setBirthplace("");
        setMobile1("");
        setMobile2("");
        setCurrentAddress("");
        setEmailId("");
      })
      .catch((error) => {
        console.error("Error adding family member:", error);
      });
  };
  useLayoutEffect(() => {
    document.body.style.background =
      "linear-gradient(to right, #000444, #0000ff)";
  }, []);
  return (
    <div className="family-member" style={{ padding: 20 }}>
      <h2  style={{ color: "red" }} > Add New Family Member  </h2>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
              required
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Mobile 1"
              value={mobile1}
              onChange={(e) => setMobile1(e.target.value)}
              fullWidth
              //required
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Mobile 2"
              value={mobile2}
              onChange={(e) => setMobile2(e.target.value)}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Email ID"
              value={emailId}
              onChange={(e) => setEmailId(e.target.value)}
              type="email"
              fullWidth
              // required
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Current Address"
              value={currentAddress}
              onChange={(e) => setCurrentAddress(e.target.value)}
              fullWidth
              multiline
              rows={3}
              //required
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Date of Birth"
              value={dob}
              onChange={(e) => setDob(e.target.value)}
              type="date"
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Birthplace"
              value={birthplace}
              onChange={(e) => setBirthplace(e.target.value)}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              select
              label="Sex"
              value={sex}
              onChange={(e) => setSex(e.target.value)}
              fullWidth
              required
            >
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              select
              label="Father"
              value={fatherId}
              onChange={(e) => setFatherId(e.target.value)}
              fullWidth
            >
              <MenuItem value="">Select Father</MenuItem>
              {members
                .sort((a, b) => a.name.localeCompare(b.name)) // Sort members by name
                .filter((member) => member.sex === "male")
                .map((member) => (
                  <MenuItem key={member.id} value={member.id}>
                    {member.name}
                    {member.partner_id
                      ? ` (Spouse: ${getSpouseName(member.partner_id)})`
                      : ""}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              select
              label="Mother"
              value={motherId}
              onChange={(e) => setMotherId(e.target.value)}
              fullWidth
            >
              <MenuItem value="">Select Mother</MenuItem>
              {members
                .sort((a, b) => a.name.localeCompare(b.name)) // Sort members by name
                .filter((member) => member.sex === "female")
                .map((member) => (
                  <MenuItem key={member.id} value={member.id}>
                    {member.name}
                    {member.partner_id
                      ? ` (Spouse: ${getSpouseName(member.partner_id)})`
                      : ""}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              select
              label="Partner"
              value={partnerId}
              onChange={(e) => setPartnerId(e.target.value)}
              fullWidth
            >
              <MenuItem value="">Select Partner</MenuItem>
              {members
                .sort((a, b) => a.name.localeCompare(b.name)) // Sort members by name
                .map((member) => (
                  <MenuItem key={member.id} value={member.id}>
                    {member.name}
                    {member.partner_id
                      ? ` (Spouse: ${getSpouseName(member.partner_id)})`
                      : ""}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <Button variant="contained" color="primary" type="submit" fullWidth>
              Add Family Member
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default AddFamilyMembers;
