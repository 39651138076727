import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, AlertTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

function ToasterAlerts({ type, title, errorMessage, handleClose, displaytime }) {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    // Set up a timer to hide the alert after 'displaytime' seconds
    const timer = setTimeout(() => {
      setVisible(false);
      handleClose(); // Optionally call handleClose if it needs to perform other actions
    }, displaytime);
  
    // Clear the timer if the component unmounts before the timer expires
    return () => clearTimeout(timer);
  }, [handleClose, displaytime]);

  if (!visible) return null; // Do not render if not visible

  return (
    <Box sx={{ width: '100%' }}>
      <Alert
        severity={type}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setVisible(false);
              handleClose();
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2 }}
      >
        <AlertTitle>{title}</AlertTitle>
        {errorMessage}
      </Alert>
    </Box>
  );
}

// Define prop types
ToasterAlerts.propTypes = {
  type: PropTypes.oneOf(['error', 'warning', 'info', 'success']).isRequired,
  title: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,

};

export default ToasterAlerts;
